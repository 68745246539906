/* global $ */
class Loader  {
	constructor(options = {}) {
		this.loader    =  (options.loaderId ? $("#"+options.loaderId+"") : $("#loader"));
		this.inSpeed   =  options.inSpeed  || 300;
		this.outSpeed  =  options.outSpeed || 300;
		this.inDelay   =  options.inDelay  || 0;
		this.outDelay  =  options.outDelay || 500;

		console.log("%c\u2713 %cLoader %cModule %cis installed","font-size:2em","font-weight:bold; font-size:1.5em;color: #007bff;"," color: #444; font-weight:bold; font-size:1.5em;","font-weight:normal; font-size:1em;");
	}

	show(target = null, callback) {
		this.loader.fadeIn(this.inSpeed, function () {
			if (callback) {
				callback(target);
			}
		});
	}

	hide(callback) {
		this.loader.delay(this.outDelay).fadeOut(this.outSpeed, function () {
			if (callback) {
				callback();
			}
		});
	}
}